.page-head {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @include media-breakpoint-up(md) {
    
  }
}

.brand {
  background: url(../../dist/images/china-works-logo-mobile.svg) no-repeat center left;
  display: inline-block;
  height: 2.5rem;
  margin: 1rem 0;
  text-indent: -9999px;
  width: 13.5rem;

  @include media-breakpoint-up(md) {
    background: url(../../dist/images/china-works-logo-inline.svg) no-repeat center center;
    width: 30rem;

    html[lang="cy"] & {
      background: url(../../dist/images/china-works-logo-inline-cymru.svg) no-repeat center center;
    }
  }
}


//Banner and page header
.banner {
  background: $brand-primary;
  color: white;
  position: relative;
}

  .banner__image {
    @include object-fit(cover, center);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  .banner__content {
    padding-top: 15rem;
    text-align: center;
    @include clearfix;

    @include media-breakpoint-up(md) {
      padding-top: 35vh;
    }

    @include media-breakpoint-up(lg) {
      padding-top: 50vh;
    }

    .banner--no-image & {
      padding-top: 0;
    }
  }

  .banner__headline {
    background: rgba($brand-primary, 0.8);
    float: left;
    padding: 1rem;
    margin: 0 -15px;
    text-align: left;

    .banner--no-image & {
      padding: 2rem 1rem;
    }
  }
  
  .banner__text {
    background: rgba($gray, 0.8);
    clear: left;
    display: block;
    float: left;
    @include font-size(1);
    padding: 0.5rem 1rem;
    margin: 0 -15px;
    text-align: left;

    @include media-breakpoint-up(md) {
      @include font-size(1.25);
      max-width: 80%;
    }

    @include media-breakpoint-up(xl) {
      max-width: 60%;
    }

  }

  .banner__button {
    background: rgba($gray-dark, 0.4);
    border: 2px solid white;
    color: white;
    clear: left;
    display: inline-block;
    margin: 1rem 0 0;
    width: 80%;

    @include media-breakpoint-up(md) {
      float: left;
      
      width: auto;
    }

    @include media-breakpoint-up(lg) {
      margin: 1rem -1rem 0;
    }
  }