@import url('https://fonts.googleapis.com/css?family=Libre+Baskerville|Open+Sans:400,400i,700');

/**
 * Font Rendering
 */
body, button, input, textarea {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/**
 * Headings
 */
h1, h2, h3 {
  font-weight: 400;
}

h4, h5, h6 {
  font-family: $font-family-base;
  font-weight: 700;
}

h1 { 
  @include font-size(1.75);
  line-height: 1em;

  @include media-breakpoint-up(md) {
    @include font-size(2);
  }

  @include media-breakpoint-up(lg) {
    @include font-size(2.5);
  }
}

h2 { 
  @include font-size(1.25);
  margin: 2.5rem 0 1rem;

  @include media-breakpoint-up(md) {
    @include font-size(1.5);
  }

  @include media-breakpoint-up(lg) {
    @include font-size(2);
  }
}

h3 {
  color: $brand_secondary;
   @include font-size(1.125);
  margin: 2rem 0 0.5rem;

  @include media-breakpoint-up(lg) {
    @include font-size(1.5);
  }
}

h4{
   @include font-size(1.125);
   margin: 1.5rem 0 0.5rem;

  @include media-breakpoint-up(lg) {
    @include font-size(1.25);
  }
}

h5 {
  @include font-size(1);
  margin: 1rem 0 0;
}

h6 {
  @include font-size(0.875);
  color: $gray;
  margin: 0;
}

/**
 * Paragraph
 */
p:empty {
  display: none;
}

p {
  margin-bottom: 1rem;

  @include media-breakpoint-up(md) {
    margin-bottom: 1.5rem;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: 2rem;
  }
}