/**
 * Primary Nav
 */
.nav-primary {
  position: relative;
  z-index: z($elements, 'navigation');
}

// Nav Toggle

// Hamburger variables
$hamburger-line-width: 40px;
$hamburger-line-height: 4px;
$hamburger-line-spacing: 6px;
$hamburger-transition-speed: 0.2s;

.nav-toggle {
  cursor: pointer;
  margin-left: auto;
  margin-right: -15px;
  padding: $spacer;
  z-index: 100;
  transition: opacity $hamburger-transition-speed;

  @include media-breakpoint-up(md) {
    display: none;
  }
}
  .nav-toggle__box {
    height: $hamburger-line-height * 3 + $hamburger-line-spacing * 2;
    position: relative;
    width: $hamburger-line-width;
  }

  .nav-toggle__inner {
    bottom: 0;
    transition-duration: 0.13s;
    transition-delay: 0.13s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

    &,
    &:before,
    &:after {
      background-color: $gray-light;
      height: $hamburger-line-height;
      position: absolute;
      width: $hamburger-line-width;
      transition: transform 0.15s;
    }

    &:before,
    &:after {
      content: "";
      display: block;
    }

    &:before {
      top: ( $hamburger-line-spacing + $hamburger-line-height ) * -1;
      transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
                    transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    &:after {
      top: ($hamburger-line-spacing * 2 + $hamburger-line-height * 2) * -1;
        transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
                    opacity 0.1s linear;
    }
  }

  // Active state
  .nav-toggle--active {
    .nav-toggle__inner {
      transform: translate3d(0, ($hamburger-line-spacing + $hamburger-line-height) * -1, 0) rotate(-45deg);
      transition-delay: 0.22s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

      &,
      &:before,
      &:after {
        background-color: #fff;
      }

      &:before {
        top: 0;
        transform: rotate(-90deg);
        transition: top 0.075s 0.1s ease-out,
                    transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
      }

      &::after {
        top: 0;
        opacity: 0;
        transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
                    opacity 0.1s 0.22s linear;
      }
    }
  }

/**
 * Top Level Nav
 */
.nav-primary {
  z-index: z($elements, 'navigation');

  @include media-breakpoint-up(md) {
    background: #fff;
    border-top: 2px solid $gray-light;
    border-bottom: 2px solid $gray-light;
  }
}

.nav-primary__nav {
  @include list-unstyled;
  background: $brand-secondary;
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 100vh;
  left: 0;
  justify-content: center;
  margin: 0;
  opacity: 0;
  text-align: center;
  top: -4.5rem;
  transform: translatex(-100vh);
  width: 100vw;
  z-index: z($navigation-elements, 'top-level');
  transition: opacity 0s 0.2s, transform 0.2s;

  &.nav-primary__nav--active {
    opacity: 1;
    transform: translatex(0);
    transition: opacity 0s, transform 0.2s;
  }

  @include media-breakpoint-up(md) {
    @include make-container();
    @include make-container-max-widths();
    background: white;
    height: auto;
    flex-direction: row;
    justify-content: center;
    opacity: 1;
    padding: 0 !important;
    position: static;
    transform: none;
  }

  > li {
    display: block;

    @include media-breakpoint-up(md) {
      flex: 1 0 auto;
      position: relative;
    }

    &:first-child {
      background: url(../../dist/images/china-works-logomark-nav.svg) no-repeat center center;
      background-size: 40px 40px;
      display: none;
      text-indent: -999em;
    }

    > a {
      color: rgba(white, 0.7);
      display: block;
      line-height: 3em;
      @include font-size(1.5);
      font-weight: 400;
      transition: all 0.2s;

      &:hover, &:focus {
        color: rgba(white, 0.9);
        text-decoration: none;
        transform: scale(1.1);
      }

      @include media-breakpoint-up(md) {
        border-top: 2px solid $gray-light;
        border-bottom: 2px solid $gray-light;
        color: $gray-dark;
        @include font-size(1);
        font-weight: 400;
        line-height: 1rem;
        margin: -2px 0;
        padding: 1rem 0.5rem;

        &:hover, &:focus {
          background-color: $gray-lightest;
          color: $gray-dark;
          transform: scale(1);
        }
      }

      @include media-breakpoint-up(lg) {
        @include font-size(1.25);
        line-height: 1.5rem;
        padding: 1rem;
      }
    }

    &.menu-item-has-children {
      @include media-breakpoint-up(md) {
        > a {
          &:after {
            content: "\e64b";
            display: inline-block;
            font-family: themify;
            @include font-size(0.75);
            line-height: 0.8rem;
            margin-left: 0.5rem;
            vertical-align: center;
          }

          @include hover-focus {
            &:after {
              transform: rotate(90deg);
            }
          }
        }
      }
    }

    // Second level
    .sub-menu {
      @include list-unstyled;
      display: none;

      @include media-breakpoint-up(md) {
        background: white;
        border: 2px solid $gray-light;
        display: block;
        opacity: 0;
        padding: 1rem 2rem;
        position: absolute;
        left: -999rem;
        text-align: left;
        top: 100%;
        min-width: 15rem;
        transition: opacity 0.2s;
        z-index: z($navigation-elements, 'dropdown');
      }

      li {

        @include media-breakpoint-up(md) {
          
        }

        a {
          display: block;

          @include media-breakpoint-up(md) {
            color: $gray-dark;
            line-height: 1rem;
            padding: 1rem 0;

            @include hover-focus {
              font-weight: bold;
              text-decoration: none;
            }
          }
        }
      }
    }

    &:hover .sub-menu {
      @include media-breakpoint-up(md) {
        left: 0;
        opacity: 1;
      }
    }

    // current page
    &.current_page_item,
    &.current-menu-item,
    &.current_page_ancestor,
    &.current-menu-ancestor {
      > a {
        color: white;
        font-weight: 700;

        @include media-breakpoint-up(md) {
          border-bottom-color: $brand-secondary;
          color: $gray-dark;
          font-weight: 400;
        }
      }
    }

    // Language selector
    &.wpml-ls-current-language {
      display: inline-block;

      @include media-breakpoint-up(md) {
        flex: 0;
      }

      > a { 
        display: none;
      }

      .sub-menu {
        background: none;
        border: none;
        display: block;
        min-width: auto;
        opacity: 1;
        padding: 0;
        position: static;
        text-align: center;

        a {
          color: rgba(white, 0.7);
          display: block;
          @include font-size(1.5);
          font-weight: 700;
          line-height: 3em;
          transition: all 0.2s;

          &:hover, &:focus {
            color: rgba(white, 0.9);
            text-decoration: none;
            transform: scale(1.1);
          }

          @include media-breakpoint-up(md) {
            background: $gray;
            border: 2px solid $gray;
            color: #fff;
            @include font-size(1);
            font-weight: 400;
            line-height: 1rem;
            margin: -2px 0;
            padding: 1rem 0.5rem;

            &:hover, &:focus {
              background-color: $gray-dark;
              border: 2px solid $gray-dark;
              font-weight: 400;
              transform: scale(1);
            }
          }

          @include media-breakpoint-up(lg) {
            @include font-size(1.25);
            line-height: 1.5rem;
            padding: 1rem;
          }
        }
      }
    }
  }
}


/**
 * Sidebar Nav
 */
.list-pages-shortcode {
  @include list-unstyled;

  li {
    border-bottom: 1px solid $gray-lightest;
    padding-left: 1rem;
    position: relative;

    &:before {
      font-family: 'themify';
      color: $gray;
      content: "\e64a";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    a{
      color: $gray;
      display: block;
      @include font-size(1.125);
      padding: 1rem 1rem;
      text-align: center;
      transition: color 0.2s, transform 0.2s;

      @include hover-focus {
        color: $gray-dark;
        text-decoration: none;
        transform: translateX(1rem);
      }
    }

    &.current_page_item a {
      color: $brand-primary;
      font-weight: 700;
      transform: translateX(0);
    }

    //hide second layer
    ul {
      display: none;
    }
  }
}

/**
 * Footer Nav
 */
.nav-footer {

}


.nav-footer__nav {
  @include list-unstyled;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0;

  li {
    margin: 0.5rem 0;
    width: 50%;

    a {
      color: $brand-tertiary;
      display: block;
      padding: 0.5rem 0;
      transition: color 0.3s;

      @include media-breakpoint-up(md) {
        padding: 0.5rem 1rem;
      }

      @include hover-focus {
        color: white;
        text-decoration: none;
      }

      &:after {
        content: '›';
        padding-left: 0.5rem
      }
    }

    // Hide second level
    ul {
      display: none;
    }
  }
}



/**
 * Back to Top
 */
.back-to-top {
  bottom: 5%;
  opacity: 0;
  position: fixed;
  right: 10px;
  z-index: z($elements, 'tooltip');
  transition: opacity 0.5s;

  .btn {
    position: relative;
    text-indent: -999em;

    &:after {
      content: '\f062';
      display: block;
      left: 50%;
      position: absolute;
      font-family: 'FontAwesome';
      text-indent: 0;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.back-to-top--show {
  opacity: 0.4;
  animation: back-to-top--appear 0.5s ease-in-out forwards;

  &:hover { opacity: 1 };
}


