button { font-family: $font-family-base; }

.btn {
  border: 2px solid $brand-primary;
  border-radius: 0;
  color: $brand-primary;
  cursor: pointer;
  @include font-size(0.875);
  font-weight: 700;
  letter-spacing: 0.1em;
  line-height: 1.25rem;
  padding: 1rem 2rem;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.25s ease-in-out;

  &:after {
    content: '›';
    padding-left: 0.5rem
  }
}

.btn-inverse {
  background: rgba(white, 0.9);
  border: none;
  color: $brand-primary;

  @include hover-focus {
    background: white;
  }
}

.btn-primary {
  background: $brand-primary;
  color: white;
}