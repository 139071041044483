// Plan your Visit
.map-info {
  margin-bottom: 2rem;
} 

.map-info__inner {
  padding-left: 3rem;
  position: relative;
}

.map-info__icon {
  @include font-size(2);
  color: $brand-primary;
  position: absolute;
  left: 0;
  top: 0.5rem
}

.map iframe {
  max-width: 100%;
}

.directions-list ul {
  @include list-unstyled;
}

.directions {
  margin-bottom: 1rem;
}

.directions__inner {
  padding-left: 3.5rem;
  position: relative;
}

.directions__icon {
  color: $brand-secondary;
  position: absolute;
  left: 0;
  top: 0;
}



// Facilities
.facility--tea-room .facility__heading {
  margin-top: 0;
}