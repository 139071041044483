
.content { 
  padding: 1.5rem 0;

  @include media-breakpoint-up(md) {
    padding: 2rem 0;
  }

  @include media-breakpoint-up(lg) {
    padding: 3rem 0;
  }
}

.main {
  > h2:first-child {
    margin-top: 0;
  }
}