.feature-box {
  display: flex;
  margin: 0 0 2.5em;
}

  .feature-box__inner {
    background: $gray-lightest;
    position: relative;
    padding-bottom: 4rem;
    width: 100%;
  }

  .feature-box__content {
    padding: 2em;
  }

  .feature-box__title {
    margin-top: 0;
  }

  .feature-box__link {
    position: absolute;
    text-align: right;
    bottom: 2rem;
    right: 2rem;

    a:empty { display: none; }
  }

  .feature-box__image {
    @include object-fit(cover, center);
    max-height: 12.5rem;
    width: 100%;
  }

  .feature-box--large {
    .feature-box__inner {
      padding-bottom: 0;
    }

    .feature-box__content {
      padding: 2rem 3rem 6rem;
    }

    .feature-box__image {
      @include media-breakpoint-up(md) {
        max-height: 17rem;
      }
    }
  }