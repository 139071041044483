.content-info {
  background: $gray-dark;
  color: rgba(white, 0.9);
  padding: 2rem 0;

  a {
    color: $brand-tertiary;
  }
}

.footer-newsletter {
  text-align: center;

  .form-group {
    text-align: left;
  }

  .form-button {
    text-align: right;
  }

  .btn {
    background-color: transparent;
    border: 2px solid $brand-tertiary;
    color: $brand-tertiary;

    @include hover-focus {
      background: $brand-tertiary;
      color: white;
    }
  }
}

.footer-newsletter__header {
  margin-top: 0;
}

.copyright {
  text-align: center;
}

.copyright__logo {
  margin-bottom: 1rem;
  opacity: 0.9;
  width: 60%;
}