// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;
$container-max-widths: (
  sm: 100%,
  md: 100%,
  lg: 960px,
  xl: 1200px
);

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$old-brick: #921F23;
$endeavour: #0050A0;
$anzac: #E6B050;
$glade-green: #648C64;
$can-can: #D28CAA;
$nepal: #8CB4BE;

$brand-primary:         $endeavour;
$brand-secondary:       $old-brick;
$brand-tertiary:        $anzac;

$gray-dark:             #2C2C2C;
$gray:                  #565A56;
$gray-light:            #AAAEAA;
$gray-lightest:         #EDF0ED;

$body-color: $gray-dark;

// Fonts
$font-family-base:      'Open Sans', sans-serif;
$headings-font-family:   'Libre Baskerville', serif;

//Z-Index Lists
$elements: covers, tooltip, modals, navigation;
$navigation-elements: top-level, dropdown, overlay;
$covers: background, text;