// Logo
.home .brand, html[lang="cy"] .home .brand {
  @include media-breakpoint-up(md) {
    background: url(../../dist/images/china-works-logo-full.svg) no-repeat center left;
    height: 6.5rem;
    margin: 2rem 0;
    width: 37rem;
  }
}

// Banner
.home .banner__content {
  padding-top: 4rem;
  padding-bottom: 4rem;

  @include media-breakpoint-up(md) {
    padding-top: 15vh;
    padding-bottom: 15vh;
  }

  @include media-breakpoint-up(lg) {
    padding-top: 20vh;
    padding-bottom: 20vh;
  }
}

// Sections

.home section:not(.banner) {
  padding-top: 2rem;
  padding-bottom: 2rem;

  @include media-breakpoint-up(md) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  @include media-breakpoint-up(lg) {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

// About
.museum-info {
  background: $brand-secondary;
  color: white;

  .row {
    align-items: center;
  }
}

  .museum-info__item {
    margin-bottom: 1.5rem;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }

  .museum-info__inner {
    padding-left: 2.5rem;
    position: relative;

    @include media-breakpoint-up(lg) {
      @include font-size(1.25);
      padding-left: 3.5rem;
    }

    i {
      @include font-size(1.5);
      position: absolute;
      left: 0;
      top: 0.25rem;

      @include media-breakpoint-up(lg) {
        @include font-size(2);
      }
    }
  }

  .museum-info__heading {
    margin-top: 0;
  }

  .museum-info__text p {
    margin: 0;
  }

  .museum-info__cta {
    text-align: right;

    .btn {
      color: $brand-secondary;

      @include media-breakpoint-up(md) {
        display: block;
      }
    }
  }

// Featured sections

.upper-featured__item, .lower-featured__item {

  &:first-child {
    margin-bottom: 2rem;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }

  a {
    display: block;
    color: white;
    position: relative;
    transition: opacity 0.2s;

    @include hover-focus {
      opacity: 0.8;
    }
  }

  img {
    border: 2px solid $gray-light;
    @include object-fit(cover, center);
    height: 10rem;
    width: 100%;

    @include media-breakpoint-up(md) {
      height: 18rem;
    }

    @include media-breakpoint-up(xl) {
      height: 22rem;
    }
  }
}

.upper-featured__heading, .lower-featured__heading {
  margin-top: 0;
}

.upper-featured__caption, .lower-featured__caption {
  @include font-size(1.25);
}

.upper-featured__caption {
  background: $brand-primary;
  bottom: 2px;
  left: 2px;
  margin: 0;
  padding: 0.5rem 1rem;
  position: absolute;
}

.lower-featured__item img {
  height: 15rem;
}

.lower-featured__item a:before {
  background: rgba($gray-dark, 0.6);
  content: '';
  display: block;
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
}

  .lower-featured__content {
    position: absolute;
    text-align: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
  }

  .lower-featured__heading {
    color: white;
  }

// Events

.upcoming-events {
  background: $gray-lightest;

  h2 {
    margin-top: 0;
  }

  ul {
    @include list-unstyled;
    margin-bottom: 0;
  }
}

.upcoming-event {
  margin-bottom: 1rem;

  @include media-breakpoint-up(md) {
    margin-bottom: 0;
  }  

  img {
    @include object-fit(cover, center);
    width: 100%;
  }

  a {
    text-decoration: none;
  }
}
  .upcoming-event__inner {
    background: white;
    border: 2px solid darken($gray-light, 10%);
    display: block;
  }

  .upcoming-event__heading {
    margin: 0;
    padding: 0.5rem 1rem;
  }

  .upcoming-event__content {
    padding: 0 1rem 1rem;
    @include clearfix;
  }

  .upcoming-event__date {
    color: $gray-light;
    margin-bottom: 1rem;
  }

  .upcoming-event__button {
    float: right;
  }
  


// Social Links
.social-links {
  background: $brand-secondary;
  color: white;
  text-align: center;

  ul {
    @include list-unstyled;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 0;
  }
}

  .social-links__heading {
    margin-top: 0;
  }

  .social-links__icon {
    margin: 0 1rem;

    a {
      color: white;
      display: block;
      height: 3rem;
      width: 3rem;
      position: relative;
      text-indent: -999rem;
      transition: color 0.2s;

      &:after{
        content: '';
        display: block;
        font-family: 'FontAwesome';
        @include font-size(3);
        line-height: 1em;
        position: absolute;
        top: 0;
        left: 0;
        text-indent: 0;
      }

      @include hover-focus {
        color: $brand-tertiary;
        text-decoration: none;
      }
    }
  }

  .social-link--facebook a:after {
    content: '\f230';
  }
  .social-link--twitter a:after {
    content: '\f099';
  }
  .social-link--instagram a:after {
    content: '\f16d';
  }