//Z Index Calc
@function z($list, $element) {
   $z-index: index($list, $element);

   @if $z-index {
      @return $z-index;
   }

   @warn 'There is no item "#{$element}" in this list; choose one of: #{$list}';
   @return null;
}

// Object Fit Polyfill
@mixin object-fit($fit: fill, $position: null){
  -o-object-fit: $fit;
     object-fit: $fit;
  @if $position {
    -o-object-position: $position;
       object-position: $position;
    font-family: 'object-fit: #{$fit}; object-position: #{$position}';
  } @else {
    font-family: 'object-fit: #{$fit}';
  }
}

// REM font size with fallback
@mixin font-size($sizeValue: 1.6) {
  font-size: ($sizeValue * 16) + px;
  font-size: $sizeValue + rem;
}