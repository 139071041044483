// Grid system
.main {
  @include make-col-ready();
  @include media-breakpoint-up(md) {
    @include make-col($main-sm-columns);
    .sidebar-primary & {
      @include make-col($main-sm-columns - $sidebar-sm-columns);
    }
  }
}
.sidebar {
  @include make-col-ready();
  margin-top: 2rem;

  @include media-breakpoint-up(md) {
    @include make-col($sidebar-sm-columns);
    margin-top: 0;
  }
}
