.single-item {
  display: flex;
  margin-bottom: 2rem;

  a{ 
    display: block;
    overflow: hidden;
    text-decoration: none;
    width: 100%;
  }
}

.single-item__inner {
  background: $brand-primary;
  display: flex;
  align-items: center;
  justify-items: center;
  flex-flow: column;
  flex-direction: column;
}

.single-item__image{
  width: 100%;
  height: auto;
  transition: transform 0.3s;
  
  a:hover &, a:focus & {
    transform: scale(1.2);
  }
}

.single-item__heading {
  @include font-size(1.25);
  color: white;
  background: $brand-primary;
  line-height: 1.5rem;
  margin: 0;
  padding: 0.5rem 1rem;
  position: relative;
  flex: 1;
  z-index: 2;
  transition: background 0.3s;

  a:hover &, a:focus & {
    background: darken($brand-primary, 10%);
  }
}