// Event Page
.events .content {
  background: $gray-lightest;
  position: relative;

   &:before {
    background: $gray-lightest;
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: -50vw;
    right: -50vh;
  }
}

.event {
   margin-bottom: 2rem;

  img {
    @include object-fit(cover, center);
    width: 100%;
  }

  a {
    text-decoration: none;
  }
}
  .event__inner {
    background: white;
    border: 2px solid darken($gray-light, 10%);
    display: block;
  }

  .event__heading {
    margin: 0;
    padding: 0.5rem 1rem;
  }

  .event__content {
    padding: 0 1rem 1rem;
    @include clearfix;
  }

  .event__date {
    color: $gray-light;
    margin-bottom: 1rem;
  }

  .event__button {
    float: right;
  }

// Single Event
.single-event__detail {
  padding-left: 3rem;
  position: relative;

  i {
    position: absolute;
    left: 1rem;
    top: 0;
  }

  h4 {
    margin-top: 0;
  }
}

.em-calendar {
  margin: 0 auto;
}